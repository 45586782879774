<template lang="pug">
  .quick-filters
    v-btn.quick-filters_button.mr-2(
      v-for="(filter, index) in filters"
      :key="index"
      @click="setQuickFilter(filter)"
      :class="['quick-filter-' + filter.ID, {primary: +$route.query[QUICK_FILTER_ID] === filter.ID}]"
      outlined
      :data-test="'quick-filter-' + filter.ID"
      small
    ) {{ filter.LABEL }}
</template>

<script>
import { QUICK_FILTER_ID } from '../../core/candidates-const'
import { DATE_FORMAT_CRM_BACKEND, TWO_WEEKS_IN_MILLISECONDS } from '@/util/const'

export default {
  data: () => ({
    QUICK_FILTER_ID,
    filters: [
      {ID: 0, LABEL: 'Available slots', QUERY: {hasAvaliableSlots: true}},
      {ID: 1, LABEL: 'Expired in two weeks', QUERY: {queueExamDateBefore: null, queueExamDateExcludeAfter: null, queueExamDateStrict: true}},
      {ID: 2, LABEL: 'Unpaid', QUERY: {isCoursePaid: false}},
      {ID: 3, LABEL: 'online', QUERY: {courseType: 'online'}},
      {ID: 4, LABEL: 'Unpaid online', QUERY: {isNotPaidOnline: true}}
    ]
  }),

  methods: {
    setQuickFilter(filter) {
      if (Number(this.$route.query[this.QUICK_FILTER_ID]) !== filter.ID)
        this.$emit('click:quickFilter', {query: this.queryModification(filter), id: filter.ID})
      else
        this.$emit('click:clearQuickFilter')
    },
    calcCandidateDateWhenExamExpiredInTwoWeeks() {
      let date = new Date(Date.now() + TWO_WEEKS_IN_MILLISECONDS);
      return this.$dates(date).format(DATE_FORMAT_CRM_BACKEND)
    },
    queryModification(params) {
      if (params.ID === 1) {
        params.QUERY.queueExamDateBefore = this.calcCandidateDateWhenExamExpiredInTwoWeeks()
        params.QUERY.queueExamDateExcludeAfter = this.calcCandidateDateWhenExamExpiredInTwoWeeks()
        return params.QUERY;
      }
      return params.QUERY;
    }
  }
}
</script>

<style lang="scss">
.quick-filters {
  width: 100%;
  display: flex;
  padding: 10px;
  justify-content: flex-end;
  align-items: center;

  @include media("<=tablet") {
    display: block;
  }

  .quick-filters_button {
    border-color: #4e7fed;
    color: #4e7fed;
    transition: all 0.25s ease-in-out;

    @include media("<=tablet") {
      margin-bottom: 4px;
    }

    span {
      @include media("<=tablet") {
        font-size: 11px;
      }
    }

    &:hover {
      transition: all 0.25s ease-in-out;
      background-color: #4e7fed;
      border-color: #4e7fed;
      color: white;
    }

    &.primary {
      color: white;
    }
  }
}
</style>
